<template>
  <div class="cont">
    <div class="row">
      <div class="col-md-2">
        <Nav class="resnone" style="margin-top: 0px" />
      </div>

      <div class="col-md-10">
        <div class="row r1">
          <div class="col-md-4 nameofpage">Edit Post</div>
        </div>

        <div class="edit_form">
          <div class="row">
            <div class="col-12">
              <div class="img_cont text-center">
                <img
                  :src="`https://api.frontendautoxpert.com.ng/public/uploads/${product.file}`"
                  alt=""
                  class="img"
                />
              </div>

              <div class="thumbnail-images">
                <img
                  v-for="(image, index) in product.images"
                  :key="index"
                  :src="`https://api.frontendautoxpert.com.ng/public/uploads/${image.filename}`"
                />
              </div>
            </div>
          </div>
          <br />
          <br />
          <div class="row">
            <div class="col-6">
              <div class="label">Product Name</div>
              <div class="animate__animated animate__fadeIn">
                <input
                  class="input"
                  type="text"
                  placeholder=""
                  v-model="product.product_name"
                />
              </div>
            </div>

            <div class="col-6">
              <div class="label">Product Category</div>
              <div class="animate__animated animate__fadeIn">
                <select class="input" v-model="product.category">
                  <option value="Select a category" disabled>
                    Select a category
                  </option>
                  <option value="car">Car</option>
                  <option value="spare parts">Spare Parts</option>
                  <option value="solar panels">Solar Panels</option>
                  <option value="batteries">Battery</option>
                </select>
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="label">Original Price</div>
              <div class="animate__animated animate__fadeIn">
                <input
                  class="input"
                  type="text"
                  placeholder=""
                  v-model="product.original_price"
                />
              </div>
            </div>
            <div class="col-6">
              <div class="label">Selling Price</div>
              <div class="animate__animated animate__fadeIn">
                <input
                  class="input"
                  type="text"
                  placeholder="Discount price"
                  v-model="product.selling_price"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-6">
              <div class="label">Quantity</div>
              <div class="animate__animated animate__fadeIn">
                <input
                  class="input"
                  type="number"
                  placeholder=""
                  v-model="product.quantity"
                />
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-12">
              <div class="label">Product Description</div>
              <div class="animate__animated animate__fadeIn">
                <textarea
                  class="textarea"
                  v-model="product.description"
                ></textarea>
              </div>
            </div>
          </div>
          <br />

          <div class="row">
            <div class="col-12">
              <div class="Submitrow" data-aos="">
                <button
                  type="submit"
                  class="submit update animate__animated animate__fadeInUp bg-primary"
                  v-on:click="updateAdmin()"
                >
                  Submit
                </button>

                <button
                  type="submit"
                  class="submit update animate__animated animate__fadeInUp bg-danger"
                  v-on:click="submit()"
                >
                  Cancel
                </button>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <!-- col-6 end -->
  </div>
</template>

<script>
import Nav from "./plugins/Sidenav.vue";
import { api, url } from "./../api";
import $ from "jquery";

export default {
  name: "Edit-product",
  components: {
    Nav,
  },
  data() {
    return {
      product: JSON.parse(localStorage.getItem("product")),
    };
  },
  methods: {
    updateAdmin() {
      $("#request_loader-container").show();

      api
        .post(url(`admin/update/post/${this.product.id}`), {
          product_name: this.product.product_name,
          category: this.product.category,
          original_price: this.product.original_price,
          selling_price: this.product.selling_price,
          quantity: this.product.quantity,
          product_body: this.product.description,
        })
        .then((response) => {
          $("#request_loader-container").hide();
          // window.location = "/product-list";
          this.$router.push("/product-list");

          swal({
            title: "Success",
            text: "Admin profile was updated",
            icon: "success",
            button: "Done",
          });

          localStorage.setItem("admin_name", this.name);
          localStorage.setItem("admin_email", this.email);
          localStorage.setItem("admin_phone", this.phone);
        })
        .catch(function (error) {
          $("#request_loader-container").hide();

          swal({
            title: "Error MEssage",
            text: `${error.response.data.message}`,
            icon: "error",
            button: "Retry",
          });
        });
    },
  },
  //computed(){},
  mounted() {
    // AOS.init()
    // alert(this.file)
  },
};
</script>

<style scoped>
.edit_form {
  padding: 20px;
  padding-top: 0px;
}
.none,
.desknone {
  display: none;
}
.cont {
  padding: 0px;
  margin-top: 60px;
  color: black;
  font-size: 15px;
  padding-bottom: 10px;
}

.r1 {
  margin-top: 40px;
}
.nameofpage {
  font-size: 21px;
}

/* input rules */
.inputcont {
  width: 100%;
  height: 40px;
  margin: 20px 0px 50px 0px;
}

.input {
  width: 100%;
  margin-left: 0%;
  height: 42px;
  padding-left: 10px;
  background-color: white;
  border: 1px solid black;
  border-radius: 4px;
  outline: none;
}
.label {
  margin-left: 0%;
  font-size: 14px;
  color: black;
  margin-top: 20px;
  display: block;
}

.textarea {
  height: 160px;
  width: 100%;
  border: 1px solid black;
  margin-top: 10px;
  margin-bottom: 20px;
}
.update {
  width: 15%;
  height: 42px;
  /* background-color: #dc3545; */
  border-radius: 4px;
  margin-top: 10px;
  margin-bottom: 0px;
  margin-right: 10px;
  color: white;
  border: none;
  font-weight: bold;
  cursor: pointer;
}
.img {
  height: 400px;
  width: 400px;
}
.Submitrow {
  text-align: right;
}

.thumbnail-images {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.thumbnail-images img {
  width: 100px;
  height: 80px;
  margin: 0 8px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 5px;
}
@media screen and (max-width: 480px) {
}

@media screen and (max-width: 780px) {
  .process_form {
    padding: 5px;
    margin-top: 70px;
  }
  .edit_form {
    margin-left: 0%;
    padding: 5px;
    padding-top: 0px;
    overflow-x: hidden;
    padding-bottom: 10px;
  }
  .update {
    width: 45%;
  }
  .abouthead {
    padding-left: 5px;
    overflow: none;
  }
  .img {
    height: 250px;
    width: 100%;
  }
}
@media screen and (max-width: 1280px) {
  /* .cont  {
    padding: 5px;
    margin-top: 0px;
  }
  .r1 {
  margin-top: 0px;
} */
}
</style>
