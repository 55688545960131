<template>
  <div class="contt">
    <div class="cont">
      <!-- <h4 class="abouthead" data-aos=" "></h4> -->
      <!-- <h4 class="sitename" data-aos="zoom-in">Frontend Autoxpert</h4> -->

      <div class="formcont">
        <div class="abouthead" data-aos=" ">Signup</div>

        <div class="llabel" data-aos=" ">Name</div>
        <div class="incont" data-aos=" ">
          <input type="text" class="inputs in1" v-model="name" />
        </div>

        <div class="llabel" data-aos=" ">Email</div>
        <div class="incont" data-aos=" ">
          <input type="Email" class="inputs in1" v-model="email" />
        </div>

        <!-- <div class="llabel" data-aos=" ">Phone</div>
        <div class="incont" data-aos=" ">
          <input type="Email" class="inputs in1" v-model="phone" />
        </div> -->

        <div class="llabel" data-aos=" ">Password</div>
        <div class="incont" data-aos=" ">
          <input
            type="password"
            class="inputs pin"
            id="password"
            v-model="password"
          />
        </div>

        <!-- <div class="llabel" data-aos=" ">Re-type Password</div>
<div class="incont" data-aos=" ">
<input type="password"  class="inputs pin" id="password" v-model="password2" >
</div> -->

        <div class="nob">
          <div class="already" data-aos="">
            Already have an account?
            <router-link to="/" class="alink" style="color: #dc3545"
              >Login</router-link
            >
          </div>
          <!-- <div class="forgot">
            <router-link to="/" class="alink" style="color: black"
              >Forgot password</router-link
            >
          </div> -->
        </div>
        <br />
        <br />
        <br />
        <div class="submit" v-on:click="submit()">Signup</div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery"

import AOS from "aos";
import "aos/dist/aos.css";
import { api, url } from "./../api";

export default {
  name: "SignupPage",
  components: {},
  data() {
    return {
      returned: "",
      name: "",
      email: "",
      phone: "09033445522",
      password: "",
    };
  },
  methods: {
    submit: function () {
      if (
        this.name == "" &&
        this.email == "" &&
        this.phone == "" &&
        this.password == "" &&
        this.role == ""
      ) {
        alert("inputs can not be empty");
      } else {
            $("#request_loader-container").show();

        api
          .post(url("admin/signup"), {
            name: this.name,
            email: this.email,
            phone: this.phone,
            password: this.password,
          })
          .then((response) => {
            $("#request_loader-container").hide();

            this.returned = response.data;
            if (this.returned.status == "success") {
              this.$router.push("/");
              // alert(this.returned.messge)
              swal({
                title: "Success",
                text: "Sign up Sucessful",
                icon: "success",
                button: "Done",
              });
              console.log(this.returned.admin);
            }
          })
          .catch(function (error) {
            $("#request_loader-container").hide();

            swal({
              title: "Error MEssage",
              text: `${error.response.data.message}`,
              icon: "error",
              button: "Retry",
            });
            // console.log('Error', error.message);
          });
      }
    },
  },
  mounted() {
    AOS.init();

    // $(".submit").click(function(){window.location.href='#/Load'})
  },
};
</script>

<style scoped>
.abouthead {
  font-size: 24px;
  margin-bottom: 20px;
  margin-top: 10px;
  color: #dc3545;
  text-align: center;
  font-weight: bold;
}

.sitename {
  color: #dc3545;
  font-size: 28px;
  font-weight: bolder;
  padding-left: 0px;
  margin-bottom: 20px;

  text-align: center;
}
.none,
.mview {
  display: none;
}

input {
  outline: none;
}
.cont {
  background-color: white;
  background-position: 0px 0px;
  background-size: 100%;
  background-repeat: no-repeat;
  animation: bgani 30s linear infinite;
  animation-delay: 1s;

  height: 100%;
  width: 100%;
  margin-left: 0%;
  padding-top: 60px;
  padding-bottom: 150px;
  overflow-x: hidden;
  position: fixed;
}
/* background-image: url(".../assets/s3.png"); */

@keyframes bgani {
  0% {
    background-position: 1200 0px;
  }
  50% {
    background-position: 50% 0px;
  }
  100% {
    background-position: 100% 0px;
  }
}

/* #9403363c */
.formcont {
  width: 50%;
  margin-left: 25%;
  background-color: #d8d8d862;
  border-radius: 12px;
  padding: 2px;
  margin-top: 10px;
  color: white;
  font-size: 17px;
  padding-bottom: 40px;
  height: auto;
  text-align: center;
}
.inputs {
  width: 100%;
  margin-left: 0%;
  border: none;
  height: 100%;
  border-radius: 10px;
  background-color: white;
  color: black;
  padding: 10px;
}
/* .inimg{float: left;width:35px;height: 35px;padding: 9px;} */
.pin {
  width: 100%;
}
.incont {
  width: 90%;
  margin-left: 5%;
  border: none;
  border-radius: 10px;
  text-align: left;
  margin-top: 0px;
  height: 40px;
  margin-bottom: 40px;
  box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19);
}
.submit {
  width: 50%;
  margin-left: 25%;
  background-color: #dc3545;
  color: white;
  border-radius: 15px;
  height: 40px;
  padding-top: 8px;
  cursor: pointer;
}
.llabel {
  color: black;
  text-align: left;
  margin-left: 5%;
  margin-bottom: 0px;
}

/* .alink{text-decoration: none;color: rgb(37, 37, 204);} */
/* .alink:hover{font-weight: bold;} */
a {
  text-decoration: none;
  color: white;
}
.in1 {
  float: right;
  border-radius: 5px;
}
.cont {
  display: block;
}

.already {
  margin-bottom: 10px;
  float: left;
  color: black;
}
.forgot {
  margin-bottom: 10px;
  float: right;
  color: black;
}
.nob {
  border: none;
  border: none;
  box-shadow: 0px;
  width: 90%;
  margin-left: 5%;
  margin-bottom: 0px;
  margin-top: 0px;
}

.am {
  color: black;
  margin-top: -110px;
  margin-bottom: 40px;
}
.check1 {
  margin-right: 10px;
}
.r {
  background-color: white;
  border: none;
  margin-left: -1%;
  margin-top: -0px;
}

@media screen and (max-width: 480px) {
  .formcont {
    width: 90%;
    margin-left: 5%;
    background-color: #d8d8d862;
    border-radius: 12px;
    padding: 2px;
    margin-top: 10px;
    color: white;
    font-size: 17px;
    padding-bottom: 40px;
    height: auto;
    text-align: center;
  }

  .cont {
    padding-top: 70px;
    padding-bottom: 20px;
    overflow-x: hidden;
    position: fixed;
  }
  .incont {
    margin-bottom: 30px;
  }
}
</style>
