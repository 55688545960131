<template>
  <div class="head">
    <div class="row">
      <div class="col-md-3">
        <!-- <span class="site_name"> <img src="" alt="" class="logoo">  </span>  -->
        <router-link to="/product-list" data-aos="zoom-in">
          <span class="fa fa-bars icon bar desknone" @click="showmenu()"></span>
          <span class="fa fa-close icon x desknone" @click="hidemenu()"
            >&#xf00d;</span
          >
          <div class="sitename">Frontend Autoxpert</div></router-link
        >
      </div>
      <div class="col-md-4"></div>

      <div class="col-md-5 resnone">
        <div class="profilehead_cont">
          <span class="welcome">Welcome back!</span>
          <span class="name">{{ name }}</span>
          <span class="fa fa-user font-icon"></span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import $ from "jquery";
import AOS from "aos";
import "aos/dist/aos.css";

export default {
  data() {
    return {
      name: localStorage.getItem("admin_name"),
    };
  },
  name: "Header",
  components: {},
  methods: {
    showsubnav: function () {
      $("#adown").toggle();
      $("#aup").toggle();
      $(".subnav").toggle(500);
    },
    showmenu: function () {
      $(".resmenu").show(500);
      $(".x").show();
      $(".bar").hide();
    },
    hidemenu: function () {
      $(".resmenu").hide(500);
      $(".bar").show();
      $(".x").hide();
    },
  },
  mounted() {
    AOS.init();
  },
};

// window.addEventListener('scroll', function(){
// console.log(window.scrollY)
// if (window.scrollY!=0) {
// $("#head").css("background-color","rgba(0, 0, 0, 0.788)").css("color","white")
// }

// if (window.scrollY==0) {
// $("#head").css("background-color","transparent").css("color","steelblue")
// //$(".r4,.card,.contributors").hide()
// }
// })
</script>

<!-- Add "scoped" attribute to limit CSS to this component only  #101112;-->
<style scoped>
/* .logoo{width: 280px;height: 90px;margin-top: -30px;} */

/* .navs{margin-left: 10px;margin-right: 10px;color:#a8a899;font-size: 15px;text-decoration: none;}
.home{color: white;}
.navs:hover{color: white;font-weight: bold;} */

.head {
  padding: 30px 40px 0px 40px;
  background-color: white;
  position: fixed;
  height: 80px;
  width: 100%;
  z-index: 2;
  top: 0px;
  /* box-shadow: 4px 4px 4px 4px rgba(199, 199, 199, 0.805); */
  /* border-bottom: 1px solid black; */
  /* box-shadow:2px 2px 2px 2px rgba(255, 255, 255, 0.801); */
  box-shadow: 4px 4px 4px 4px rgba(199, 199, 199, 0.805);
} /* top right bottom left     box-shadow:0px 1px 0px 0px rgba(255, 255, 255, 0.801);*/

/* .action_btn{width: 120px;padding: 7px;height: 35px;margin-left: 0px;margin-right: 10px;display: inline-block;
background-color: #fc5b3f;color: white; text-align: center;font-size: 14px;border-radius: 20px;text-decoration: none;} */

a {
  text-decoration: none;
}
.desknone {
  display: none;
}
.none {
  display: none;
}
/* .router-link-active{color: #fc5b3f;font-weight: bold;} */

.sitename {
  color: #dc3545;
  font-size: 28px;
  font-weight: bolder;
  padding-left: 0px;
  margin-top: -10px;
  margin-left: -20px;
  cursor:pointer

}



.font-icon {
  font-size: 30px;
  border-radius: 20%;
  /* display: inline-block; */
  border: 1px solid #dc3545;
  padding: 5px;
}
.profilehead_cont {
  display: inline-block;
  text-align: right;
  width: 100%;
  margin-top: -10px;
  cursor:pointer

}

.name {
  color: black;
  font-size: 20px;
  margin-right: 10px;
}
.x {
  display: none;
}
.welcome {
  color: #dc3545;
  font-size: 20px;
  margin-right: 10px;
}

@media screen and (max-width: 480px) {
}

@media screen and (max-width: 780px) {
    .bar {
    display: inline-block;
  }
  
  .sitename {
    color: #dc3545;
    font-size: 25px;
    font-weight: bolder;
    padding-left: 0px;
    /* margin-top: -10px; */
    margin-left: 0px;
    display: inline-block;
  }
  .head {
    padding: 20px;
    background-color: white;
    position: fixed;
    height: 60px;
  }

  .resnone {
    display: none;
  }
  .icon {
    font-size: 25px;
    margin-right: 10px;
    color: black;
  }
  .head {
  padding-left: 10px;}
}
</style>
