import axios from "axios";

// const baseURL = "https://api.frontendautoxpert.com.ng/public/api/";
const baseURL = "https://api.frontendautoxpert.com.ng/public/api/";

// https://api.frontendautoxpert.com.ng/public/
// const bearerToken = localStorage.getItem("accessToken");

const url = (path) => {
  return baseURL + path;
};

const api = axios.create();
api.interceptors.request.use((config) => {
  // config.headers.Authorization = `Bearer ${bearerToken}`;
  return config;
});

// const formdata_api = axios.create();
// api.interceptors.request.use((config) => {
//     config.headers = `{ "Content-Type": "multipart/form-data" }`;
//     return config;
// });
export { baseURL, url, api };
