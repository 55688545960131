<template>
      <div class="loader-container" id="loader-container">
      <div class="loader" id="loader"></div>
    </div>
</template>

<script>

export default {
name: 'loader',
components: {},
//data() { },
methods:{
  
}
,
mounted() {
    // Set the timeout in milliseconds

    // Function to hide the loader after a timeout
    // const hideLoader = () => {
    //   const loader = document.getElementById('loader-container');
    //   loader.style.display = 'none';
    // };

    // // Set the timeout to hide the loader
    // setTimeout(hideLoader, 3000);
}}
</script>

<style>
 .loader-container {
      display: flex;
      justify-content: center;
      align-items: center;
      height: 100vh;
      position: fixed;
      width: 100%;
      /* margin-left: 18%; */
      z-index: 999;
      background-color: rgb(255, 255, 255);
      top:0px
    }

     .loader {
      border: 8px solid #f3f3f3;
      border-top: 8px solid #dc3545;
      border-radius: 50%;
      width: 70px;
      height: 70px;
      animation: spin 1s linear infinite;
    }

    @keyframes spin {
      0% { transform: rotate(0deg); }
      100% { transform: rotate(360deg); }
    }
@media screen and (max-width:272px){

}
</style>
