import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import 'bootstrap/dist/css/bootstrap.min.css'
import 'bootstrap/dist/js/bootstrap.bundle.min.js'
import swal from 'sweetalert';
import "./assets/fontawesome/css/fontawesome.min.css";
import "./assets/fontawesome/css/all.min.css";

const app = createApp(App);

import RequestLoader from "./components/plugins/Request_loader.vue";
import SideBar from "./components/plugins/Side_bar.vue";
import Loader from "./components/plugins/Loader.vue";
import Header from "./components/plugins/Header.vue";
import DesktopSideBar from "./components/plugins/Sidenav.vue";
import ImageCropper from "./components/plugins/Image_cropper.vue";

app.component("RequestLoader", RequestLoader);
app.component("SideBar", SideBar);
app.component("Loader", Loader);
app.component("Header", Header);
app.component("DesktopSideBar", DesktopSideBar);
app.component("ImageCropper", ImageCropper);
app.use(router).mount("#app");
