<template>
  <div class="cont" style="padding-bottom: 50px">
    <div id="profile">
      <div class="row">
        <div class="col-md-2">
          <Nav class="resnone" style="margin-top: 0px" />
        </div>

        <div class="col-md-10">
          <div class="row r1">
            <div class="col-md-4 nameofpage">Profile</div>
          </div>

          <div class="dash_profile">
            <div class="text-center">
              <div class="userimgcont">
                <span class="fa fa-user"></span>
              </div>
              <!-- <div class="editpic">Edit picture</div> -->
            </div>
            <!-- <br> -->
            <div class="forms">
              <div class="ubheads text-center">Edit Admin Info</div>

              <div class="row">
                <div class="col-6">
                  <div class="inputcont">
                    <span class="label">Name</span>
                    <input type="text" class="input" v-model="admin_details.name" />
                  </div>
                </div>

                <div class="col-6">
                  <div class="inputcont">
                    <span class="label">Email</span>
                    <input type="text" class="input" v-model="admin_details.email" />
                  </div>
                </div>

                <!-- <div class="col-6">
                  <div class="inputcont">
                    <span class="label">Phone Number</span>
                    <input type="text" class="input" v-model="admin_details.phone" />
                  </div>
                </div> -->

                <div class="col-6">
                  <div class="inputcont">
                    <span class="label">Change Password</span>
                    <input type="text" class="input" v-model="password" />
                  </div>
                </div>
              </div>

              <div>
                <div class="update" @click="updateAdmin()" style="float: right">
                  Update
                </div>
              </div>
            </div>
          </div>
        </div>
        <!-- col-6 end -->
      </div>
    </div>
  </div>
</template>

<script>
import Nav from "./plugins/Sidenav.vue";
import { api, url } from "./../api";
import $ from "jquery";

export default {
  name: "Profile",
  components: {
    Nav,
  },
  data() {
    return {
      admin_details: JSON.parse(localStorage.getItem("admin_details")),
      password: "",
    };
  },
  methods: {
    updateAdmin() {
      $("#request_loader-container").show();

      api
        .post(url(`admin/update/admins/${this.admin_details.id}`), {
          name: this.admin_details.name,
          email: this.admin_details.email,
          phone: this.admin_details.phone,
          password: this.password,
        })
        .then((response) => {
          $("#request_loader-container").hide();

          swal({
            title: "Success",
            text: "Admin profile was updated",
            icon: "success",
            button: "Done",
          });

          // window.location = "/admin-list";
this.$router.push('/admin-list')
          // localStorage.setItem("admin_name", this.name);
          // localStorage.setItem("admin_email", this.email);
          // localStorage.setItem("admin_phone", this.phone);
        })
        .catch(function (error) {
          $("#request_loader-container").hide();

          swal({
            title: "Error MEssage",
            text: `${error.response.data.message}`,
            icon: "error",
            button: "Retry",
          });
        });
    },
  },
  //computed(){},
  mounted() {
    // AOS.init()
  },
};
</script>

<style scoped>
.none,
.desknone {
  display: none;
}
.cont {
  padding: 0px;
  margin-top: 60px;
  color: black;
  font-size: 15px;
  padding-bottom: 10px;
}

.r1 {
  margin-top: 40px;
}
.nameofpage {
  font-size: 21px;
}

.dash_profile {
  padding: 20px;
  padding-top: 0px;
  margin-top: 20px;
  width: 100%;
}
/* overflow-y: scroll;-ms-overflow-style: none;scrollbar-width: none;} */

.userimgcont {
  width: auto;
  background-color: white;
  height: auto;
  border-radius: 50%;
  display: inline-block;
  margin-top: 10px;
  margin-bottom: 30px;
}
.fa-user {
  font-size: 50px;
  border-radius: 50%;
  margin-top: 10%;
  display: inline-block;
}

/* input rules */
.inputcont {
  width: 100%;
  height: 40px;
  margin: 20px 0px 50px 0px;
}

.input {
  width: 100%;
  margin-left: 0%;
  height: 42px;
  padding-left: 10px;
  background-color: white;
  /* box-shadow: 0 8px 16px 0 rgba(0, 0, 0, 0.2), 0 6px 20px 0 rgba(0, 0, 0, 0.19); */
  border-radius: 4px;
  border: none;
  outline: none;
  border: 1px solid black;
}
.label {
  margin-left: 0%;
  font-size: 14px;
  color: black;
  margin-top: 0px;
  display: block;
}

.update {
  width: 20%;
  margin-left: 0%;
  height: 40px;
  color: white;
  padding-top: 5px;
  background-color: #dc3545;
  font-weight: bold;
  text-align: center;
  border-radius: 4px;
  margin-top: 10px; /*border: 2px solid #dc3545*/
  padding-top: 10px;
  cursor: pointer;
}
/* .editpic {
  font-size: 12px;
  margin-top: 0px;
} */

@media screen and (max-width: 480px) {
}

@media screen and (max-width: 780px) {
  .dash_profile {
    margin-left: 0%;
    padding: 5px;
    padding-top: 0px;
    overflow-x: hidden;
    padding-bottom: 10px;
  }
  .update {
    width: 45%;
  }
  .nameofpage {
    text-align: center;
    overflow: none;
  }
}

@media screen and (max-width: 1280px) {
   /* .cont  {
    padding: 5px;
    margin-top: 0px;
  }
  .r1 {
  margin-top: 0px;
} */
}
</style>
