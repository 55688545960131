<template>
  <!-- imag edit modal -->

  <!-- Modal -->
  <div id="modal" class="modal">
    <div class="modal-backdrop" id="modalBackdrop"></div>
    <div class="modal-content">
      <span class="close-btn" id="closeModalBtn">&times;</span>
      <div class="modal-header">
        <h5 class="modal-title" id="EditImageLabel">Upload Thumbnail Image</h5>
      </div>
      <div class="modal-body">
        <div>
          <br />

          <input
            ref="input"
            type="file"
            name="image"
            accept="image/*"
            @change="setImage"
          />
          <!-- style="box-shadow: 2px 2px 2px 2px #cccccc93;" -->
          <div class="content">
            <div class="row">
              <div class="col-lg-8 col-sm-8 col-12 ps-0">
                <section class="cropper-area">
                  <div class="img-cropper">
                    <vue-cropper
                      id="cropper"
                      ref="cropper"
                      :aspect-ratio="600 / 600"
                      :src="imgSrc"
                      preview=".preview"
                    />
                  </div>
                  <div class="actions_single">
                    <a
                      href="#"
                      role="button"
                      @click.prevent="zoom(0.2)"
                      class="fa fa-search-plus faicon"
                      title="Zoom in"
                    ></a>
                    <a
                      href="#"
                      role="button"
                      @click.prevent="zoom(-0.2)"
                      class="fa fa-search-minus faicon"
                      title="Zoom out"
                    ></a>
                    <a
                      href="#"
                      role="button"
                      @click.prevent="move(-10, 0)"
                      class="fa fa-angle-left faicon"
                      title="Move left"
                    ></a>
                    <a
                      href="#"
                      role="button"
                      @click.prevent="move(10, 0)"
                      class="fa fa-angle-right faicon"
                      title="Move Right"
                    ></a>
                    <a
                      href="#"
                      role="button"
                      @click.prevent="move(0, -10)"
                      class="fa fa-angle-up faicon"
                      title="Move up"
                    ></a>
                    <a
                      href="#"
                      role="button"
                      @click.prevent="move(0, 10)"
                      class="fa fa-angle-down faicon"
                      title="Move Down"
                    ></a>
                    <a
                      href="#"
                      role="button"
                      @click.prevent="rotate(90)"
                      class="fa fa-rotate-right faicon"
                      title="Rotate +90deg"
                    ></a>
                    <a
                      href="#"
                      role="button"
                      @click.prevent="rotate(-90)"
                      class="fa fa-rotate-left faicon"
                      title="Rotate -90deg"
                    ></a>

                    <a
                      ref="flipX"
                      href="#"
                      role="button"
                      @click.prevent="flipX"
                      class="fa fa-angle-double-right faicon"
                      title="Flip X"
                    ></a>
                    <a
                      ref="flipY"
                      href="#"
                      role="button"
                      @click.prevent="flipY"
                      class="fa fa-angle-double-up faicon"
                      title="Flip Y"
                    ></a>
                    <a
                      href="#"
                      role="button"
                      @click.prevent="cropImage"
                      class="fas fa-crop-alt faicon"
                      title="Crop Image"
                    ></a>
                    <a
                      href="#"
                      role="button"
                      @click.prevent="showFileChooser"
                      class="fas fa-upload faicon"
                      title="Upload another image"
                    ></a>
                  </div>

                  <!-- <textarea v-model="data" /> -->
                </section>

                <div class="upload_shadow_single">
                  <a
                    href="#"
                    role="button"
                    @click.prevent="showFileChooser"
                    class="fa fa-upload upload-icon"
                    title="Upload image"
                  ></a>
                </div>
              </div>
              <div class="col-lg-4 col-sm-4 col-12 ps-0">
                <section class="preview-area">
                  <p>Preview</p>
                  <div class="preview" />
                  <br />
                  <br />
                  <p>Cropped Image</p>
                  <div class="cropped-image">
                    <img
                      v-if="cropImg"
                      :src="cropImg"
                      alt="Cropped Image"
                      id="cropedimg"
                    />
                    <div v-else class="crop-placeholder" />
                  </div>
                </section>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="modal-footer align-items-right">
        <span
          type="button"
          class="btn btn-cancel bg-danger"
          @click="hide_modal()"
        >
          Close
        </span>
        <button type="button" class="btn btn-primary" @click="save_iamge()">
          Save
        </button>
      </div>
    </div>
  </div>

  <!-- /Static Backdrop -->
</template>

<script>
import VueCropper from "vue-cropperjs";
import "cropperjs/dist/cropper.css";
import $ from "jquery";

export default {
  components: {
    VueCropper,
  },
  data() {
    return {
      imgSrc: null,
      cropImg: "",
      data: null,
    };
  },
  methods: {
    hide_modal() {
      $("#modal").hide();
    },
    b64toBlob(b64Data, contentType = "", sliceSize = 512) {
      const byteCharacters = atob(b64Data);
      const byteArrays = [];

      for (
        let offset = 0;
        offset < byteCharacters.length;
        offset += sliceSize
      ) {
        const slice = byteCharacters.slice(offset, offset + sliceSize);

        const byteNumbers = new Array(slice.length);
        for (let i = 0; i < slice.length; i++) {
          byteNumbers[i] = slice.charCodeAt(i);
        }

        const byteArray = new Uint8Array(byteNumbers);
        byteArrays.push(byteArray);
      }

      const blob = new Blob(byteArrays, { type: contentType });
      blob.name = "image.png";
      return blob;
    },
    save_iamge() {
      var preview = document.getElementById("preview_thumbnail");
      preview.src = this.cropImg;
      var dk = document.getElementById("uploaded");
      dk.style = "display:block";
      $("#modal").hide();

      // alert(typeof this.cropImg);

      const base64Data = this.cropImg.replace(/^data:image\/\w+;base64,/, ""); // Ensure this is a valid base64 string
      const contentType = "image/png"; // Adjust content type as needed
      const blob = this.b64toBlob(base64Data, contentType);

      this.$emit("CroppedImage", blob);

      // console.log(blob);
      // this.cropImg = "";
      // this.imgSrc = "";
    },

    cropImage() {
      // get image data for post processing, e.g. upload or setting image src
      this.cropImg = this.$refs.cropper.getCroppedCanvas().toDataURL();

      // var preview = document.getElementById("preview_thumbnail");
      // preview.src = this.cropImg;
      // var dk = document.getElementById("uploadd");
      // dk.style = "display:block";
    },
    flipX() {
      const dom = this.$refs.flipX;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleX(scale);
      dom.setAttribute("data-scale", scale);
    },
    flipY() {
      const dom = this.$refs.flipY;
      let scale = dom.getAttribute("data-scale");
      scale = scale ? -scale : -1;
      this.$refs.cropper.scaleY(scale);
      dom.setAttribute("data-scale", scale);
    },
    getCropBoxData() {
      this.data = JSON.stringify(this.$refs.cropper.getCropBoxData(), null, 4);
    },
    getData() {
      this.data = JSON.stringify(this.$refs.cropper.getData(), null, 4);
    },
    move(offsetX, offsetY) {
      this.$refs.cropper.move(offsetX, offsetY);
    },
    reset() {
      this.$refs.cropper.reset();
    },
    rotate(deg) {
      this.$refs.cropper.rotate(deg);
    },
    setCropBoxData() {
      if (!this.data) return;

      this.$refs.cropper.setCropBoxData(JSON.parse(this.data));
    },
    setData() {
      if (!this.data) return;

      this.$refs.cropper.setData(JSON.parse(this.data));
    },
    setImage(e) {
      const file = e.target.files[0];

      if (file.type.indexOf("image/") === -1) {
        alert("Please select an image file");
        return;
      }

      if (typeof FileReader === "function") {
        const reader = new FileReader();

        reader.onload = (event) => {
          this.imgSrc = event.target.result;
          // rebuild cropperjs with the updated source
          this.$refs.cropper.replace(event.target.result);

          $(".actions_single").show();
        };

        reader.readAsDataURL(file);
      } else {
        alert("Sorry, FileReader API not supported");
      }
    },
    showFileChooser() {
      this.$refs.input.click();
      $(".upload_shadow_single").hide();
    },
    zoom(percent) {
      this.$refs.cropper.relativeZoom(percent);
    },
  },
  mounted() {
    if (this.imgSrc == null) {
      $(".actions_single").hide();
    } else {
      $(".actions_single").show();
    }

    const modalBackdrop = document.getElementById("modalBackdrop");

    $("#openModalBtn").click(function () {
      $("#modal").show();
    });
    $("#closeModalBtn").click(function () {
      $("#modal").hide();
    });
    //     $("#modalBackdrop").click(function () {
    //       $("#modal").hide();
    //     });
    //     $("window").click(function (event) {
    //        if (event.target === modalBackdrop) {
    //                 $("#modal").hide();
    // }});
  },
};
</script>

<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped>
#openModalBtn {
  padding: 10px 20px;
  font-size: 16px;
  cursor: pointer;
}

.modal {
  display: none; /* Hidden by default */
  position: fixed;
  z-index: 999; /* Sit on top */
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  overflow: auto;
}

.modal-backdrop {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(223, 223, 223, 0.863); /* Black with opacity */
  z-index: -2;
}

.modal-content {
  position: relative;
  margin: auto;
  padding: 20px;
  width: 100%;
  max-width: 70%;
  height: auto;
  background-color: #fff;
  border-radius: 10px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
  animation: fadeIn 0.3s ease-out;
  padding-bottom: 20px;
}

.close-btn {
  position: absolute;
  top: 10px;
  right: 20px;
  font-size: 28px;
  font-weight: bold;
  color: #aaa;
  cursor: pointer;
}

.close-btn:hover,
.close-btn:focus {
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.card {
  text-align: center;
}

.card img {
  max-width: 100%;
  height: auto;
  border-radius: 10px;
}

/* Animation for the modal */
@keyframes fadeIn {
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
}

input[type="file"] {
  display: none;
}

/* .header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 10px 0 5px 0;
} */

.header h2 {
  margin: 0;
}

.header a {
  text-decoration: none;
  color: black;
}

/* .content {
  display: flex;
  justify-content: space-between;
} */

.cropper-area {
  width: 614px;
}

.actions_single {
  margin-top: 40px;
  height: 60px;
  padding: 10px;
  background-color: black;
  border-radius: 10px;
  align-content: center;
  align-items: center;
}

/* .actions_single a {
  display: inline-block;
  padding: 5px 15px;
  background: #0062cc;
  color: white;
  text-decoration: none;
  border-radius: 3px;
  margin-right: 1rem;
  margin-bottom: 1rem;
} */

.preview-area {
  width: 60%;
  margin-top: 00px;
  /* box-shadow: 2px 2px 2px 2px #cccccc93; */
  align-content: center;
  align-items: center;
}

.preview-area p {
  font-size: 1.25rem;
  margin: 0;
  margin-bottom: 20px;
}

.preview-area p:last-of-type {
  margin-top: 1rem;
}

.preview {
  width: 100%;
  height: calc(372px * (600 / 600));
  overflow: hidden;
}

.crop-placeholder {
  width: 100%;
  height: 200px;
}

.cropped-image img {
  max-width: 100%;
}

.faicon {
  font-size: 24px;
  margin: 6px;
  margin-right: 20px;
  color: #00afef;
  cursor:grab
}
.upload_shadow_single {
  width: 60%;
  height: 480px;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.418);
  top: 6%;
  align-items: center;
  align-content: center;
  text-align: center;
}
.upload-icon {
  font-size: 60px;
  color: black;
  cursor:grab

}
#cropper {
  height: 450px;
  width: 100%;
}

.right-image-conts {
  width: 100%;
  height: 200px;
}
/* vue-cropper{} */

@media (max-width: 950px) {
  .cropper-area {
    width: 100%;
  }

  .upload_shadow_single {
    width: 100%;
    height: 350px;
    position: absolute;
    background-color: rgba(0, 0, 0, 0.418);
    top: 6%;
    align-items: center;
    align-content: center;
    text-align: center;
    margin-left: -5px;
  }

  .actions_single {
    margin-top: 40px;
    height: auto;
    padding: 10px;
    background-color: black;
    border-radius: 10px;
    text-align: center;
  }

  .preview-area {
    width: 100%;
    margin-top: 20px;
    align-content: center;
    align-items: center;
    text-align: center;
  }

  .faicon {
    font-size: 18px;
    margin: 6px;
    margin-right: 6px;
    color: #00afef;
  }


.modal-content {
  max-width: 100%;
}
}

a {
  text-decoration: none;
}


@media screen and (max-width: 1580px) {
  .preview-area {
  width: 90%;
  margin-left: 5%;
}
#cropper {
  height: 350px;
  width: 90%;
}
.actions_single{width:90%}

.right-image-conts {
  width: 150px;
  height: 150px;
}
.preview {
  width: 100%;
  height: calc(250px * (600 / 600));
  overflow: hidden;
}
}
</style>
