<template>
  <div class="cont">
    <ImageCropper id="modal" @CroppedImage="get_blob" />

    <div class="row r1">
      <div class="col-md-2"><Nav class="resnone" /></div>
      <div class="col-md-10">
        <div class="process_form">
          <h5 class="abouthead">Add a product</h5>

          <div class="formcont form1">
            <br />

            <div class="row">
              <div class="col-6">
                <div class="label">Product Name</div>
                <div class="animate__animated animate__fadeIn">
                  <input
                    class="input"
                    type="text"
                    placeholder=""
                    v-model="product_name"
                  />
                </div>
              </div>

              <div class="col-6">
                <div class="label">Product Category</div>
                <div class="animate__animated animate__fadeIn">
                  <select class="input" v-model="category">
                    <option value="Select a category" disabled>
                      Select a category
                    </option>
                    <option value="car">Car</option>
                    <option value="spare parts">Spare Parts</option>
                    <option value="solar panels">Solar Panels</option>
                    <option value="batteries">Battery</option>
                  </select>
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="label">Original Price</div>
                <div class="animate__animated animate__fadeIn">
                  <input
                    class="input"
                    type="text"
                    placeholder=""
                    v-model="original_price"
                  />
                </div>
              </div>
              <div class="col-6">
                <div class="label">Selling Price</div>
                <div class="animate__animated animate__fadeIn">
                  <input
                    class="input"
                    type="text"
                    placeholder="Discount price"
                    v-model="selling_price"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-6">
                <div class="label">Quantity</div>
                <div class="animate__animated animate__fadeIn">
                  <input
                    class="input"
                    type="number"
                    placeholder=""
                    v-model="quantity"
                  />
                </div>
              </div>
            </div>
            <div class="row">
              <div class="col-12">
                <div class="label">Product Description</div>
                <div class="animate__animated animate__fadeIn">
                  <textarea class="textarea" v-model="product_body"></textarea>
                </div>
              </div>
            </div>

            <div class="row">
              <div
                class="col-12 col-sm-12 col-md-2 col-xl-2"
                @click="show_modal()"
              >
                <div class="reveal_image_processor" @click="show_modal()">
                  <span class="fa fa-plus add-icon" @click="show_modal()"></span
                  ><br />
                  <span class="">Add Thumbnail</span>
                </div>
              </div>

              <div class="col-12 col-sm-12 col-md-1 col-xl-1"></div>
              <div class="col-12 col-sm-12 col-md-6 col-xl-6">
                <div class="uploaded thumbnail-images" id="uploaded">
                  <img
                    src=""
                    alt=""
                    class="preview_thumbnail"
                    id="preview_thumbnail"
                  />
                </div>
              </div>
            </div>
            <br />
            <div class="row">
              <div class="col-12 col-sm-12 col-md-2 col-xl-2">
                <div class="reveal_image_multi_image_input" id="click_file">
                  <span class="fa fa-plus add-icon"></span><br />
                  <span class="" id="click_file">Add 5 Image</span>
                </div>
                <input
                  type="file"
                  accept="image/*"
                  name="note"
                  id="file"
                  multiple
                  hidden
                  @change="handleFilesSelected"
                />
              </div>

              <!-- <div class="col-12 col-sm-12 col-md-1 col-xl-1"></div> -->
              <!-- {{ previewFiles }} -->
              <div class="col-12 col-sm-12 col-md-6 col-xl-6">
                <div class="thumbnail-images">
                  <img
                    v-for="(image, index) in previewFiles"
                    :key="index"
                    :src="image"
                  />
                </div>
              </div>
            </div>

            <div class="row">
              <div class="col-12">
                <div class="Submitrow" data-aos="">
                  <!-- <button type="submit" class="submit set_sub animate__animated animate__fadeInUp pressed bg-dark" @click="save()"> Save</button> -->
                  <button
                    type="submit"
                    class="submit set_sub animate__animated animate__fadeInUp bg-primary"
                    v-on:click="submit()"
                  >
                    Post
                  </button>
                  <!-- <button
                    type="submit"
                    class="submit set_sub animate__animated animate__fadeInUp bg-success"
                    v-on:click="submit()"
                  >
                    Preview
                  </button> -->
                  <button
                    type="submit"
                    class="submit set_sub animate__animated animate__fadeInUp bg-danger"
                    v-on:click="submit()"
                  >
                    Cancel
                  </button>
                </div>
              </div>
            </div>
            <!-- form end  -->
          </div>
        </div>

        <br /><br />
      </div>
    </div>
  </div>
</template>

<script>
import axios from "axios";
import $ from "jquery";

import Nav from "./plugins/Sidenav.vue";

import AOS from "aos";
import "aos/dist/aos.css";
// import Chart from 'chart.js/auto';

export default {
  name: "Dashboard",
  components: {
    Nav,
  },
  data() {
    return {
      returned: "",
      category: "Select a category",
      product_name: "",
      product_body: "",
      selling_price: null,
      original_price: null,
      quantity: null,
      file: null,
      selectedFiles: [], // Array to store the selected files
      previewFiles: [], // Array to store the file URLs for preview
    };
  },
  methods: {
    // open_img_selector(){
    //  const file = document.getElementById('file')
    //  file.click()
    // },
    handleFilesSelected(event) {
      const maxFiles = 5;
      const files = Array.from(event.target.files);

      if (files.length > maxFiles) {
        alert(`You can only select up to ${maxFiles} images.`);
        return;
      }

      // Clear the previous selections
      this.selectedFiles = [];
      this.previewFiles = [];

      // Store the selected files and create preview URLs
      files.slice(0, maxFiles).forEach((file) => {
        this.selectedFiles.push(file);
        const reader = new FileReader();
        reader.onload = (e) => {
          this.previewFiles.push(e.target.result);
        };
        reader.readAsDataURL(file);
      });
    },
    get_blob(blob) {
      this.file = blob;
      console.log(this.file);
    },
    show_modal() {
      $("#modal").show();
    },
    submit: function () {
      // var file = document.getElementById("file");
      // // var video = document.getElementById('video')
      // var file = file.files[0];
      // // var video_file = video.files[0];

      if (this.product_name == "" && this.product_body == "") {
        swal({
          title: "Error",
          text: "Inputs cannot be empty",
          icon: "error",
          button: "Try again",
        });
      } else {
        // var formData = new FormData();
        // // formData.append("file", file, file.name);
        // formData.append("file", this.file);
        // formData.append("product_name", this.product_name);
        // formData.append("product_body", this.product_body);
        // formData.append("category", this.category);
        // formData.append("selling_price", this.selling_price);
        // formData.append("original_price", this.original_price);
        // formData.append("quantity", this.quantity);
        // formData.append("images", this.selectedFiles);
        // console.log(formData);

        $("#request_loader-container").show();
        axios({
          method: "post",
          url: "https://api.frontendautoxpert.com.ng/public/api/admin/create/post",
          data: {
            file: this.file,
            product_name: this.product_name,
            product_body: this.product_body,
            category: this.category,
            selling_price: this.selling_price,
            original_price: this.original_price,
            quantity: this.quantity,
            images: this.selectedFiles,
          },
          headers: { "Content-Type": "multipart/form-data" },
        })
          .then((response) => {
            $("#request_loader-container").hide();

            this.returned = response.data;

            if (this.returned.status == "success") {
              swal({
                title: "Success",
                text: "Product Uploaded.",
                icon: "success",
                button: "Done",
              });
            }
            // window.location = "/product-list";
            this.$router.push("/product-list");
          })
          .catch(function (error) {
            console.log(error);

            $("#request_loader-container").hide();

            swal({
              title: "Error Message",
              text: `${error.response.data.message}`,
              icon: "error",
              button: "Retry",
            });
            // console.log('Error', error.message);
          });
      }
    },
  },

  //computed(){},
  mounted() {
    AOS.init();
    document
      .getElementById("click_file")
      .addEventListener("click", function () {
        document.getElementById("file").click();
      });
  },
};
</script>

<style scoped>
.none,
.desknone {
  display: none;
}

/* body,
* {
  overflow-x: hidden;
} */

.thumbnail-images {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.thumbnail-images img {
  max-width: 100px;
  height: 80px;
  margin: 0 8px;
  cursor: pointer;
  border: 1px solid #ccc;
  border-radius: 5px;
}
#cont {
  padding: 0px;
  margin-top: 0px;
  color: black;
  font-size: 15px;
  overflow-x: hidden;
}
.process_form {
  margin-top: 70px;
  padding-bottom: 6px;
  overflow-x: hidden;
}
.input {
  margin-top: 0px;
  /* border-top: 3px solid white; */
  height: 42px;
  width: 100%;
  margin-top: 10px;
  margin-bottom: 20px;
  margin-left: 0%;
  background-color: white;
  border-radius: 4px;
  font-size: 14px;
  padding-left: 10px;
  border: none;
  color: black;
  border: 1px solid black;
  outline: none;
  /* box-shadow: 2px 2px 4px 4px rgba(197, 197, 197, 0.944); */
}
/* border:2px solid #dc3545; */
input {
  outline: none;
}
.abouthead {
  padding-left: 40px;
  /* overflow: none; */
}
.formcont {
  margin-top: 0px;
  width: 100%;
  margin-left: 0%;
  padding: 40px;
  padding-top: 20px;
  overflow-x: hidden;
}
.textarea {
  height: 120px;
  width: 100%;
  border: 1px solid black;
  margin-top: 10px;
  margin-bottom: 20px;
  padding: 10px;
}
.set_sub {
  width: 15%;
  height: 42px;
  /* background-color: #dc3545; */
  border-radius: 4px;
  margin-top: 30px;
  margin-bottom: 0px;
  margin-right: 10px;
  color: white;
  border: none;
  font-weight: bold;
}
.filein {
  width: 42%;
  display: inline-block;
  margin-right: 5%;
}

.Submitrow {
  text-align: right;
}
.reveal_image_processor,
.reveal_image_multi_image_input {
  height: 80px;
  width: 180px;
  border: 1px dotted black;
  border-radius: 12px;
  text-align: center;
  align-content: center;
  cursor: pointer;
}
.add-icon {
  font-size: 25px;
  color: #dc3545;
}

.preview_thumbnail {
  width: 80px;
  height: 80px;
}
.uploaded {
  display: none;
}
@media screen and (max-width: 480px) {
}

@media screen and (max-width: 780px) {
  .process_form {
    padding: 5px;
    margin-top: 70px;
  }
  .formcont {
    margin-left: 0%;
    padding: 5px;
    padding-top: 0px;
    overflow-x: hidden;
    padding-bottom: 10px;
  }
  .set_sub {
    width: 45%;
  }
  .abouthead {
    padding-left: 5px;
    /* overflow: none; */
  }

  .uploaded {
    margin-top: 20px;
  }
  .preview_thumbnail {
    width: 180px;
    height: 180px;
  }
}

@media screen and (max-width: 1280px) {
  /* .process_form {
    padding: 5px;
    margin-top: 0px;
  } */
  .abouthead {
    padding-left: 20px;
    /* overflow: none; */
  }
  .formcont {
    padding: 20px;
    padding-top: 00px;
    /* overflow-x: hidden; */
  }
}
</style>
