<template>
  <div id="app">
    <Loader id="loader-container" />
    <RequestLoader />
    <Header v-if="$route.name!='Login'&&$route.name!='Signup'"/>

    <SideBar />
    <router-view />
  </div>
</template>

<script>
import router from "./router";

export default {
  name: "App",

  mounted() {
    router.beforeEach((to, from) => {
      // Function to hide the loader after a timeout
      const loader = document.getElementById("loader-container");
      loader.style.display = "flex";
      const hideLoader = () => {
        loader.style.display = "none";
      };

      setTimeout(hideLoader, 3000);
    });
  },
};
</script>

<style>
.r1 {
  margin-top: 80px;
}
.cont {
  margin-top: 30px;
  overflow: hidden;
}
.table-responsive {
  overflow: visible;
}
</style>
